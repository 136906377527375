import axios, { AxiosRequestConfig } from 'axios';
import { getToken } from 'services/auth';

interface CompanyInfo {
  name?: string
  url?: string
}

interface RequestData extends RequestInit {
  data?: any
}

const request = async (path: RequestInfo, { data, ...cleanOptions }: AxiosRequestConfig = {}) => {
  const authToken = getToken();
  const headers = {'Authorization': `Bearer ${authToken}`, 'Content-Type': 'application/json'}

  const api = process.env.REACT_APP_API_URL
  const url = `${api}/${path}`
  try {
    const res = await axios({
      url,
      data: data ? JSON.stringify(data) : undefined,
      headers: headers,
      ...cleanOptions,
    })
    return res.data
  } catch (error) {
    throw error;
  }

}

export const setCompanyInfo = async (data: CompanyInfo) => {
  try {
    const res = await request('api/employer/company/', {
      data,
      method: 'POST'
    })
    return res;
  } catch (error) {
    throw(error)
  }
}

export const getMe = async () => {
  try {
    const res = await request('api/employer/', {
      method: 'GET'
    })
    return res;
  } catch (error) {
    throw(error)
  }
}
