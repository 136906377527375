import ForceLib from '@diwala/force';
import { Dispatch, useReducer } from 'react';
import { createContainer } from 'unstated-next';
import { getMe } from 'api'
import { isEmpty } from 'utils';

const force = new ForceLib({storageBackend: localStorage, host:process.env.REACT_APP_API_URL});

export const checkToken = () => {
  return force.tokenInfo
}

export const getToken = () => {
  return force.authToken;
}

const logout = (dispatch: Dispatch<Action>) => {
  dispatch({type: 'clear'})
  force.clearAuthState()
}

const login = async (dispatch: Dispatch<Action>) => {
  try {
    const res = await getMe();
    dispatch({type: 'setState', entity: res.data.entity, user: res.data.user})
  } catch (error) {
    logout(dispatch);
  }
}

type State = {
  user: Diwala.User | {}
  entity: Diwala.Entity | {}
}

type Action =
  {type:'setUser', user:Diwala.User} |
  {type: 'setEntity', entity: Diwala.Entity} |
  {type: 'setState', entity: Diwala.Entity, user: Diwala.User} |
  {type: 'clear' }


const reducer = (state: State, action: Action): State => {
  if(action.type === 'setUser') return {...state, user: action.user}
  if(action.type === 'setEntity') return {...state, entity: action.entity}
  if(action.type === 'setState') return {user: action.user, entity: action.entity}
  if(action.type === 'clear') return {user: {}, entity: {}}
  return {...state}
}

const useAuthHook = () => {
  const [state, dispatch] = useReducer(reducer, { user: {}, entity: {} });
  const setEntity = (entity: Diwala.Entity) => dispatch({type:'setEntity', entity})
  const setState = (entity: Diwala.Entity, user: Diwala.User) => dispatch({type:'setState', entity, user})
  const localState = { authed: false, ...state}
  if(!isEmpty(state.entity)) {
    const entity = state.entity as Diwala.Entity
    if(entity.name && entity.url) localState.authed = true;
  }

  const curriedLogin = async () => {
    await login(dispatch)
  }

  const curriedLogout = () => {
    logout(dispatch)
  }

  return {
    ...localState,
    login: curriedLogin,
    logOut: curriedLogout,
    setState: setState,
    setEntity,
    hasAuthState: !!getToken()
  };
}

export const Auth = createContainer(useAuthHook);

export const Force = force;
