import React from 'react';
import { Link } from 'react-navi'
import styled from '@emotion/native';
import { BaseText } from '@diwala/killer-shrew';

import { FullScreenEmployer } from 'layouts/atoms';
import { WrappedBrandLogo, } from 'components/logos';
import { CenterView } from 'layouts/unauthed';

const ExtraBigText = styled(BaseText)`
  font-size: 64px;
  line-height: 80px;
  color: white;
`

const Layout: React.FC =({ children }) => {

  return (
    <FullScreenEmployer>
      <Link href='/'>
        <WrappedBrandLogo />
      </Link>
      <CenterView>
        <ExtraBigText>404 Not found <Link href='/'>Go back</Link></ExtraBigText>
      </CenterView>
    </FullScreenEmployer>
  )
}

//Needed because of this issue: https://github.com/microsoft/TypeScript/issues/13626
export default Layout;

