import { mount, map, lazy } from 'navi';


export default mount({
  '*': map((request, context: any) => {
    if(context.authed) return lazy(() => import('./authenticated-routes'))
    else return lazy(() => import('./login-routes'))
  }),
  '/login/start': map(() => {
    return lazy(() => import('./open.routes'))
  })
})
