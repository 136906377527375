import React from 'react';

import { FullScreenEmployer } from 'layouts/atoms';

import { DefaultSpinner} from '@diwala/killer-shrew';

const Layout =() => {

  return (
    <FullScreenEmployer style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <DefaultSpinner height='30px' width='30px'/>
    </FullScreenEmployer>
  )
}

//Needed because of this issue: https://github.com/microsoft/TypeScript/issues/13626
export default Layout;
