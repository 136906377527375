import React, { Suspense, useEffect, useCallback } from 'react'
import { Router, View, NotFoundBoundary } from 'react-navi'
import { Fonts } from '@diwala/killer-shrew';

import { Auth } from 'services/auth';

import NotFound from 'views/404';
import SuspenseView from 'views/suspense';
import routes from './router';

const App = () => {
  const { authed, entity, login, hasAuthState } = Auth.useContainer()
  const loginCallback = useCallback(login, [])

  useEffect(()=>{
    if(hasAuthState) loginCallback()
  },[loginCallback, hasAuthState])

  return (
    <Router routes={routes} context={{entity, authed}}>
      <Fonts />
      <NotFoundBoundary render={() => <NotFound />}>
        <Suspense fallback={<SuspenseView />}>
          <View />
        </Suspense>
      </NotFoundBoundary>
    </Router>
  )
}

const ProviderLayer = () => {
  return (
    <Auth.Provider>
      <App />
    </Auth.Provider>
  )
}

export default ProviderLayer;
