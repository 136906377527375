import React from 'react';
import styled from '@emotion/styled';
import { SmallBrandLogo } from '@diwala/killer-shrew';

const StyledSmallBrand = styled(SmallBrandLogo)`
  margin-left: 31px;
  margin-top: 31px;
  width: 100px;
  position: absolute;
`

export const WrappedBrandLogo = () => {

  return (
    <StyledSmallBrand color={'white'} />
  )
}
